@font-face {
    font-family: 'CASLON';
    src: url("assets/TrimMono-Medium.otf");
}

.App {
    text-align: center;
    background: none transparent;
    width: 100%;
}

.header {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: white;
    height: 62px;
    overflow: hidden;
}

.progress-title {
    font-family: CASLON, serif;
}

.progress-container {
    height: 33px;
    width: 368px;
    background-size: auto;
    background: url("assets/mid-bar.png") no-repeat 54px 5px;
    z-index: 20;
    padding: 14px 18px 0 14px;
    position: relative;
}

.progress-container-left {
    position: absolute;
    left: 0;
    top: 0;
    width: 54px;
    height: 43px;
    z-index: 20;
    background: url("assets/left-bar.png") no-repeat;
}

.progress-container-right {
    position: absolute;
    right: 0;
    top: 0;
    width: 54px;
    height: 43px;
    z-index: 20;
    background: url("assets/right-bar.png") no-repeat;
}

.progress-bar {
    position: relative;
    top: 2px;
    height: 13px;
    width: 100%;
    overflow: hidden;
}

.pulsing {
    animation: 2s infinite pulse ease;
}

@keyframes pulse {
    0% {
        padding-right: 0;
    }
    25% {
        filter: brightness(1);
    }
    50% {
        padding-right: 1px;
    }
    75% {
        filter: brightness(1.3);
    }
    100% {
        padding-right: 0;
    }
}

.filler {
    background: url("assets/bars.png") no-repeat;
    height: 100%;
    border-radius: inherit;
    transition: width .2s ease-in;
    align-content: center;
    vertical-align: center;
    float: left;
}

.filler.finished {
    background: url("assets/finished-bars.png") no-repeat;
}

.label-completed {
    color: rgb(0, 133, 146);
}

.tremble {
    animation: shake 0.5s cubic-bezier(.36, .07, .19, .97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
}

@keyframes shake {
    10%, 90% {
        transform: translate3d(-1px, 0, 0);
        color: darkred;
    }

    20%, 80% {
        transform: translate3d(2px, 0, 0);
        color: darkred;
    }

    30%, 50%, 70% {
        transform: translate3d(-3px, 0, 0);
        color: darkred;
    }

    40%, 60% {
        transform: translate3d(3px, 0, 0);
        color: darkred;
    }
}